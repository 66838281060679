<template>
  <div class="dev">
    <pre class="dev__output">{{ output }}</pre>
    <div class="dev__button-bar"><button class="btn btn-outline-primary" @click="exec">Execute</button></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { db, fb } from '@/shared/firebase'
import multiBatch from '@/shared/multiBatch'
import moment from 'moment'
import CalculateTaxTable from '@/helpers/CalculateTaxTable'
import ChangeInvoiceAddress from '@/helpers/ChangeInvoiceAddress'

export default {
  data() {
    return {
      output: null
    }
  },
  methods: {
    async exec() {}
  }
}
</script>

<style lang="scss" scoped>
.dev {
  height: 100%;
  width: 100%;
  position: relative;

  &__output {
    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow-y: scroll;

    background: #eee;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.25);
    white-space: pre-wrap;
  }

  &__button-bar {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
</style>
